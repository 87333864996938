$border-m: rgba(0, 0, 0, 0.15);
$border-white-xs: rgba(255, 255, 255, 0.2);
$border-xs: rgba(0, 0, 0, 0.08);
$break-mobile: 600px;
$break-small-small: 360px;
$error: #ff3300;
$font: "Roboto";
$grid-size: 3;
$grid-size-mobile: 4;
$grid-size-small-mobile: 3;
$hover: #f2f2f2;
$info: #b3d9ff;
$line-image-height: 25px;
$line-with-border: false;
$override-styles: false;
$primary: #ef2622;
$secondary: #ffffff;
$secondary-journey: #888;
$shadow-m: rgba(0, 0, 0, 0.3);
$shadow-xl: rgba(0, 0, 0, 0.4);
$shadow-xs: rgba(0, 0, 0, 0.15);
$shadow-xxs: rgba(0, 0, 0, 0.02);
$text: #58585a;
$warning: #ff7e00;
$header-height: 0px;
$header-height-mobile: 0px;
$board-mobile-margin-top: 80px;
$board-mobile-translateY-offset: 160px;
$board-mobile-default-top: 220px;
$library-url: "";
$tippy-color: $primary;
